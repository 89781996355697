export function changeCartCounter(counterCount, counterWrappSelector, counterSelector) {
    const cartCounterEls = document.querySelectorAll(counterWrappSelector);
    cartCounterEls.forEach((cartCounterEl) => {
        const counterEl = cartCounterEl.querySelector(counterSelector);
        if (!counterEl) {
            console.error('Не установлен счётчик, html элемент не найден');
            return;
        }
        counterEl.textContent = counterCount;
        // TODO Это для мобильной версии. Подумать как унифицировать мобильную и ПК версии
        counterEl.classList.remove('unread-message_hidden');
    });
}
