import { createHtmlElement } from '../helpers/createHtmlElement.js';
import { createVariantItem } from './createVariantItem.js';
export function createVariantsList(variants) {
    const dialogStandardSizeListEl = createHtmlElement('ul', {
        classes: ['dialog__list', 'dialog__list_standard'],
    });
    const dialogListIndividualSizeEl = createHtmlElement('ul', {
        classes: ['dialog__list', 'dialog__list_individual'],
    });
    let hasIndividualSizes = false;
    let hasStandardSizes = false;
    variants.forEach((variant) => {
        console.log('<<<< VARIANT >>>> ', variant.title);
        // console.log('<<<< el.title.toLowerCase() >>>> ', variant.title.toLowerCase());
        // console.log('<<<< el.title.toLowerCase().includes("нестандарт") >>>> ', variant.title.toLowerCase().includes('нестандарт'));
        if (variant.title.toLowerCase().includes('нестандарт')) {
            // console.log('<<<< ЕСТЬ ИНДИВИДУАЛЬНЫЕ РАЗМЕРЫ >>>> ', variant.title);
            hasIndividualSizes = true;
            const variantItemEl = createVariantItem(variant);
            dialogListIndividualSizeEl.append(variantItemEl);
        }
        else {
            if (variant.quantity > 0 && variant.available) {
                const variantItemEl = createVariantItem(variant);
                hasStandardSizes = true;
                dialogStandardSizeListEl.append(variantItemEl);
            }
        }
    });
    console.log('======== dialogStandardSizeListEl ', dialogStandardSizeListEl);
    console.log('======== dialogListIndividualSizeEl ', dialogListIndividualSizeEl);
    return { dialogStandardSizeListEl, dialogListIndividualSizeEl, hasIndividualSizes, hasStandardSizes };
}
