export function toggleStyleForCartCounter(counterWrappEl, counterEl) {
    if (!counterEl)
        return;
    if (!Number(counterEl.textContent)) {
        counterWrappEl.classList.remove('header__links-link_cart_activ');
    }
    else {
        counterWrappEl.classList.add('header__links-link_cart_activ');
    }
}
