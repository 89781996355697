import { getProductByProductId, } from '../api/getProductByProductId.js';
import { showModalWidow, modalHandler, hideModalWidow, } from '../helpers/createModalWindow.js';
import { createModalWithVariants } from '../addProductVariantToCart/createModalWithVariants.js';
import { getClosestParentBySelector } from '../helpers/getClosestParentBySelector.js';
export function showSelectedProductVariant() {
    const origin = `${window.location.protocol}//${window.location.host}`;
    console.log('<<<< ORIGIN >>>> ', origin);
    // Проверяем есть ли селект на странице выбора размеров - он в template = product
    // если элемента нет, выходим из функции
    const selectEl = document.getElementById('door-size-select');
    console.log('<<<<<<<<<<<< selectEl >>>>> ', selectEl);
    if (!selectEl)
        return;
    const parentSelectEl = selectEl?.parentElement;
    // Продолжаем скрипт, если селект есть и вешаем на него обработчик
    console.log('ПРОДОЛЖАЕМ СКРИПТ');
    async function showSelectedVariantHandler(e) {
        console.log('СРАБОТАЛ ХЭНДЛЕР');
        console.log('EVENTTTTTTTTT', e);
        // const options = selectEl?.querySelectorAll<HTMLSelectElement>('option');
        // //@ts-ignore
        // // TODO нужно типизировать
        // const variants = Array.from(options).map(option => `${option.value}: ${option.textContent}`)
        // console.log('variants >>>>>> ', variants);
        // // Если один вариант ничего не делаем, уходим из хэндлера
        // if(variants.length === 1) return;
        // const { dialogEl, addToCartMessageEl, dialogWrapperEl } =
        // createModalWithVariantsByArray(variants);
        // showModalWidow(dialogEl);
        const productId = selectEl?.getAttribute('data-id');
        if (!productId)
            return;
        console.log('productId >>>>>>> ', productId);
        // Формируем endpoint чтобы получить варианты товара
        const parameters = `front_api/products.json?ids=${productId}&with=variants`;
        const endpoint = `${origin}/${parameters}`;
        const product = await getProductByProductId(endpoint);
        if (!product) {
            return;
        }
        console.log('PRODUCT >>>>>> !!!! ', product);
        //  Если всего один вариант нет смысла показывать модалку, просто выходим из хэндлер
        if (product[0].variants.length < 2)
            return;
        const { dialogEl, addToCartMessageEl, dialogWrapperEl } = createModalWithVariants(product);
        showModalWidow(dialogEl);
        function setSelectedVariantId(e) {
            const desiredEl = getClosestParentBySelector(e, '[data-variant-id]');
            let variantId = null;
            if (desiredEl) {
                variantId = desiredEl.getAttribute('data-variant-id');
            }
            if (!variantId) {
                console.error(`Клик по кнопке с вариантом. Не найден variant-id ${variantId}`);
                return;
            }
            hideModalWidow(dialogEl);
            console.log('||||||||||| VariantId !!!! >> ', variantId);
            //@ts-ignore
            //TODO нужно типизировать
            selectEl.value = variantId;
            const event = new Event('change', { bubbles: true });
            selectEl?.dispatchEvent(event);
            // setTimeout(() => hideModalWidow(dialogEl), MODAL_DISPLAY_DELAY);
            // hideModalWidow(dialogEl);
        }
        modalHandler(dialogEl, setSelectedVariantId);
    }
    parentSelectEl?.addEventListener('click', (e) => {
        e.stopPropagation();
        console.log('e.target - ', e.target);
        console.log('selectEl - ', selectEl);
        // console.log('e.target === selectEl - ', e.target === selectEl)
        // if (e.target === selectEl) {
        showSelectedVariantHandler(e);
        // }
    });
}
