import { createHtmlElement } from './createHtmlElement.js';
import { calcScrollbarWidth } from '../helpers/calcScrollbarWidth.js';
import { closeWindowIcon } from '../icons/index.js';
import { getClosestParentBySelector } from './getClosestParentBySelector.js';
/**
 * Создание модального окна. Можно без кнопки закрытия окна (hasCloseButton = false)
 * Использование id для окна и кнопки закрытия не целесообразно, на странице может быть несколько окон. Для поиска элемента используйте data атрибуты
 *
 * @export
 * @param {?string} [classModifier] / Передать, если нужно добавить стилизацию от модификатора
 * @param {boolean} [hasCloseButton=true] / Установить в false, если кнопка закрытия окна не нужна
 * @param {string} [titleModal=""] / Задать заголовок модалке
 * @param {string} [ariaLabel="Модальное окно"] / Задать значение атрибуту aria-label (только если нет заголока).
 * @returns {ModalWindowsElements}
 */
export function createModalWindow(titleModal = "", classModifier, hasCloseButton = true, ariaLabel = "Модальное окно") {
    const dialogElClasses = classModifier ? ['dialog', classModifier] : ['dialog'];
    const attributesForDialogEl = { ['data-dialog']: "", };
    let titleId = String(Date.now());
    if (titleModal) {
        attributesForDialogEl['aria-labelledby'] = titleId;
    }
    else {
        attributesForDialogEl['aria-label'] = ariaLabel;
    }
    const dialogEl = createHtmlElement('dialog', {
        classes: dialogElClasses,
        attributes: attributesForDialogEl,
    });
    const dialogWrapperEl = createHtmlElement('div', {
        classes: ['dialog__wrapper'],
    });
    if (titleModal) {
        const dialogTitleEl = createHtmlElement('h3', {
            classes: ['dialog__title'],
            attributes: {
                'id': titleId,
                'data-dialog-content': '',
            },
            content: titleModal,
        });
        dialogWrapperEl.append(dialogTitleEl);
    }
    const dialogContentEl = createHtmlElement('div', {
        classes: ['dialog__content'],
        attributes: {
            'data-dialog-content': '',
        },
    });
    dialogWrapperEl.append(dialogContentEl);
    let dialogBtnCloseEl = null;
    if (hasCloseButton) {
        dialogBtnCloseEl = createHtmlElement('button', {
            classes: ['dialog__btn-close'],
            attributes: {
                type: 'button',
                'data-close-btn': '',
                'aria-label': 'Закрыть модальное окно',
            },
            content: closeWindowIcon,
        });
        dialogWrapperEl.append(dialogBtnCloseEl);
    }
    dialogEl.append(dialogWrapperEl);
    return {
        dialogEl,
        dialogWrapperEl,
        dialogContentEl,
        dialogBtnCloseEl,
    };
}
/**
 * Функция показа мобильного окна. Предусматривает наличие css правила с классом scroll-lock
 *
 * @export
 * @param {HTMLDialogElement} dialog
 * @param {number} scrollbarWidth
 */
export function showModalWidow(dialog) {
    const scrollbarWidth = calcScrollbarWidth();
    // document.body.append(dialog);
    document.documentElement.classList.add('mob-scroll-lock');
    document.body.classList.add('scroll-lock');
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.append(dialog);
    dialog.showModal();
}
/**
 * Функция скрытия мобильного окна. Предусматривает наличие css правила с классом scroll-lock
 *
 * @export
 * @param {HTMLDialogElement} dialog
 */
export function hideModalWidow(dialog) {
    console.log('close');
    document.body.style.paddingRight = '0';
    document.documentElement.classList.remove('mob-scroll-lock');
    document.body.classList.remove('scroll-lock');
    dialog.close();
    dialog.remove();
}
export function modalHandler(dialog, callback) {
    dialog.addEventListener('click', (e) => {
        console.log('<<<<<<  handlerModal. Объект события', e);
        const dialogEl = e.currentTarget;
        console.log('<<<<<<  handlerModal. Где произошло', dialogEl);
        const isClickedOnBackDrop = e.target === dialogEl;
        console.log('isClickedOnBackDrop', isClickedOnBackDrop);
        const isClickOnBtnClose = getClosestParentBySelector(e, '[data-close-btn]') !== null;
        if (isClickOnBtnClose || isClickedOnBackDrop)
            hideModalWidow(dialogEl);
        callback(e);
    });
}
