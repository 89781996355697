export const MODAL_DISPLAY_DELAY = 1200; // интервал времени (ms) показа модального окна
import { getPageSettingsData } from './components/getPageSettingsData.js';
import { addProductVariantToCart } from './components/addProductVariantToCart/addProductVariantToCart.js';
import { showSelectedProductVariant } from './components/showSelectedProductVariant/showSelectedProductVariant.js';
export const pageData = getPageSettingsData();
if (pageData) {
    if (pageData.template === 'index' ||
        pageData.template === 'collection' ||
        pageData.collectionHandle === 'razdvijnye-dveri' ||
        pageData.collectionHandle === 'dvustvorchatye-dveri' ||
        pageData.template === 'collection.search' ||
        pageData.template === 'collection.zel-goroda') {
        addProductVariantToCart();
    }
    if (pageData.template === 'product') {
        showSelectedProductVariant();
    }
}
