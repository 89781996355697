/**
 * Экспорт из JSON переменных, формирующихся liquid для страниц сайта. Переменные формируются скриптом в конце footer.liquid
 *
 * @export
 * @returns {(PageData | undefined)}
 */
export function getPageSettingsData() {
    const pageDataEl = document.getElementById('page-data');
    if (!pageDataEl) {
        console.error('Не удалось найти JSON с liqiud данными страницы (script#page-data)');
        return;
    }
    const pageData = JSON.parse(pageDataEl.textContent || '');
    return pageData;
}
