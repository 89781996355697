/**
 * Вывод глобальных переменных темы в консоль. Переменные устанавливаются в theme-variables.liquid.
 * Только для отладки работы скриптов.
 *
 * @export
 */
export function printGlobalSettingsToConsole() {
    console.log('========================================');
    console.log('<<<<<<<  GLOBAL SETTINGS  >>>>>>>');
    console.log('window.themeSettings.isDesktop - ', window.themeSettings.isDesktop);
    console.log('window.themeSettings.isMobile - ', window.themeSettings.isMobile);
    console.log('window.themeSettings.useCache - ', window.themeSettings.useCache);
    console.log('window.themeSettings.useUniqueReviews - ', window.themeSettings.useUniqueReviews);
    console.log('window.themeSettings.page_size_small - ', window.themeSettings.page_size_small);
    console.log('window.themeSettings.page_size_big - ', window.themeSettings.page_size_big);
    console.log('window.themeSettings.isWorkProject - ', window.themeSettings.isWorkProject);
    console.log('window.themeSettings.globalCache - ', window.themeSettings.globalCache);
    console.log('----------------------------------------');
}
