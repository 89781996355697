import { getJsonData } from "../helpers/api/getJsonData.js";
export async function getProductByProductId(endpoint) {
    try {
        const data = await getJsonData(endpoint, 'Получение варинтов товара - ручка ${endpoint}');
        return data.products;
    }
    catch (error) {
        throw error;
    }
}
