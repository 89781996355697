/**
 * Создание HTML элемента
 *
 * @export
 * @template {keyof HTMLElementTagNameMap} T
 * @param {T} tag
 * @param {Options} options
 * @returns {HTMLElementTagNameMap[T]}
 */
export function createHtmlElement(tag, options) {
    const element = document.createElement(tag);
    if (options.classes) {
        if (Array.isArray(options.classes)) {
            element.classList.add(...options.classes);
        }
        else {
            element.classList.add(options.classes);
        }
    }
    if (options.attributes) {
        for (let [key, value] of Object.entries(options.attributes)) {
            element.setAttribute(key, value);
        }
    }
    if (options.content) {
        const htmlTagRegex = /<\/?[\w\s="/.':;#-\/]+>/gi;
        // const htmlTagRegex = /^\s*<svg[\s\S]*>[\s\S]*<\/svg>\s*$/i;
        if (typeof options.content === 'string' || typeof options.content === 'number') {
            if (htmlTagRegex.test(options.content)) {
                // Если content содержит теги и svg-код, используем innerHTML
                element.innerHTML = options.content;
            }
            else {
                element.textContent = options.content;
            }
        }
        else if (options.content instanceof HTMLElement) {
            element.appendChild(options.content);
        }
        else if (Array.isArray(options.content)) {
            options.content.forEach(item => {
                if (typeof item === 'string' || typeof item === 'number') {
                    if (htmlTagRegex.test(item)) {
                        // Если item содержит теги и svg-код, используем innerHTML
                        element.innerHTML += item;
                    }
                    else {
                        element.appendChild(document.createTextNode(item));
                    }
                }
                else if (item instanceof HTMLElement) {
                    element.appendChild(item);
                }
            });
        }
    }
    return element;
}
