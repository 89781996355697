export async function getJsonData(endpoint, errorMessage = "") {
    try {
        const response = await fetch(endpoint);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status} ${errorMessage}`);
        }
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(`Error fetching data: ${error} ${errorMessage}`);
        throw error;
    }
}
