/**
 * Расчет ширины вертикального скроллбара в px
 *
 * @export
 * @returns {number} (без ед. измерения)
 */
export function calcScrollbarWidth() {
    // Создаем временный элемент 
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // Всегда показывать скроллбар
    outer.style.position = 'absolute';
    outer.style.top = '-9999px';
    document.body.appendChild(outer);
    // Создаем внутренний элемент
    const inner = document.createElement('div');
    outer.appendChild(inner);
    // Вычисляем ширину скроллбара
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
    // Удаляем временный элемент
    document.body.removeChild(outer);
    return scrollbarWidth;
}
