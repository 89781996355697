import { createHtmlElement } from '../helpers/createHtmlElement.js';
export function createVariantItem(variant) {
    // const regex = /(?:от)* (?:\d+\-*\,*\ *)+(?:до)*x* (?:\d+\-*\,*\ *)+/g;
    const regexNumbers = /\b\d+\b/g;
    const regexBrackets = /(\w)(?=[\(])/g;
    let newVariantTitle = variant.title
        // Сначала добавляем <br> перед кавычками
        .replace(regexBrackets, function (match) {
        return match + '<br>';
    })
        // Затем делаем числа жирными
        .replace(regexNumbers, function (match) {
        return '<b>' + match + '</b>';
    });
    console.log('777777777777 >>>>> newVariantTitle ', newVariantTitle);
    const variantLiEl = createHtmlElement('li', {
        classes: ['dialog__item'],
    });
    const variantBtnEl = createHtmlElement('button', {
        classes: ['variant-btn'],
        attributes: {
            type: 'button',
            'data-variant-id': String(variant.id),
        },
        // content: `${variant_title_new}`,
        content: `${newVariantTitle}`,
    });
    variantLiEl.append(variantBtnEl);
    return variantLiEl;
}
