import { createModalWindow, } from "../helpers/createModalWindow.js";
import { createHtmlElement } from "../helpers/createHtmlElement.js";
import { createVariantsList } from "./createVariantsList.js";
import { checkMarkIcon } from "../icons/checkMarkIcon.js";
export function createModalWithVariants(product) {
    const { dialogEl, dialogWrapperEl, dialogContentEl, dialogBtnCloseEl } = createModalWindow("Выберите размер");
    const { dialogStandardSizeListEl, dialogListIndividualSizeEl, hasIndividualSizes, hasStandardSizes } = createVariantsList(product[0].variants);
    // const tabWrapperEl = createHtmlElement("div", {
    //   classes: ["tabs"],
    // });
    // const standardTab = createHtmlElement("label", {
    //   classes: ["tabs__label"],
    //   content: "Стандартный",
    // });
    // const standardTabInput = createHtmlElement("input", {
    //   classes: ["tabs__input", "tabs__input_standard", "visually-hidden"],
    //   attributes: {
    //     type: "radio",
    //     name: "tabs",
    //     checked: "true",
    //   },
    // });
    // standardTab.append(standardTabInput);
    // const individualTab = createHtmlElement("label", {
    //   classes: ["tabs__label"],
    //   content: "Индивидуальный",
    // });
    // const individualTabInput = createHtmlElement("input", {
    //   classes: ["tabs__input", "tabs__input_individual", "visually-hidden"],
    //   attributes: {
    //     type: "radio",
    //     name: "tabs",
    //   },
    // });
    // individualTab.append(individualTabInput);
    // tabWrapperEl.append(standardTab, individualTab);
    // dialogContentEl.append(
    //   tabWrapperEl,
    //   dialogStandardSizeListEl,
    //   dialogListIndividualSizeEl
    // );
    // ========================================
    const forIndividualSizeLabelClasses = hasIndividualSizes ? ["tabs__label", "tabs__label_individual"] : ["tabs__label", "tabs__label_individual", "unavailable"];
    const forStandardSizeLabelClasses = hasStandardSizes ? ["tabs__label", "tabs__label_standard"] : ["tabs__label", "tabs__label_standard", "unavailable"];
    // if (!hasIndividualSizes) {
    // console.log("<<<<<<<<<< dialogStandardSizeListEl ", dialogStandardSizeListEl)
    // dialogContentEl.append(dialogStandardSizeListEl);
    // } else {
    const standardText = createHtmlElement("span", {
        classes: ["tabs__text", "tabs__text_standard"],
        attributes: {
            tabindex: "0"
        },
        content: "Стандартный",
    });
    const standardLabel = createHtmlElement("label", {
        classes: forStandardSizeLabelClasses,
        attributes: {
            for: "standard-sizes",
        },
        content: standardText,
    });
    const standardTabInput = createHtmlElement("input", {
        classes: ["tabs__input", "tabs__input_standard", "visually-hidden"],
        attributes: {
            id: "standard-sizes",
            type: "radio",
            name: "tabs",
            ...(hasStandardSizes && { checked: "" })
        },
    });
    const individualText = createHtmlElement("span", {
        classes: ["tabs__text", "tabs__text_individual"],
        attributes: {
            tabindex: "0"
        },
        content: "Индивидуальный",
    });
    const individualLabel = createHtmlElement("label", {
        classes: forIndividualSizeLabelClasses,
        attributes: {
            for: "individual-sizes",
        },
        content: individualText,
    });
    const individualTabInput = createHtmlElement("input", {
        classes: ["tabs__input", "tabs__input_individual", "visually-hidden"],
        attributes: {
            id: "individual-sizes",
            type: "radio",
            name: "tabs",
            ...(!hasStandardSizes && hasIndividualSizes && { checked: "" })
        },
    });
    dialogContentEl.append(standardTabInput, standardLabel, individualTabInput, individualLabel, dialogStandardSizeListEl, dialogListIndividualSizeEl);
    // }
    // -------------------------------------------------
    /*
    if (hasIndividualSizes) {
      const individualSizesEl = createHtmlElement("p", {
        classes: ["individual-sizes-mess"],
      });
  
      const iconWrap = createHtmlElement("span", {
        classes: ["individual-sizes-mess__icon"],
        content: checkMarkIcon,
      });
  
      // const textEl = createHtmlElement('span', {
      //   classes: ['individual-sizes-mess__text'],
      //   content: 'Возможен индивидуальный размер.',
      // });
  
      // individualSizesEl.append(iconWrap, textEl);
      individualSizesEl.append(iconWrap);
      dialogContentEl.append(individualSizesEl);
    }
  */
    const addToCartMessageEl = createHtmlElement("div", {
        classes: ["add-to-card-mess"],
    });
    addToCartMessageEl.innerHTML = checkMarkIcon;
    // TODO лучше заменить и сделать не в модалке, а в всплывающем окне dialog
    const addToCartTextEl = createHtmlElement("span", {
        classes: ["add-to-card-mess__text"],
        content: "Товар добавлен в корзину",
    });
    addToCartMessageEl.append(addToCartTextEl);
    dialogEl.append(addToCartMessageEl);
    return {
        dialogEl,
        addToCartMessageEl,
        dialogWrapperEl,
        dialogBtnCloseEl,
    };
}
