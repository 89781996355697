import { getPageSettingsData } from "../getPageSettingsData.js";
/**
 * Вывод переменных liquid в консоль. Переменные экспортируются .
 * Только для отладки работы скриптов.
 *
 * @export
 */
export function printPageSettingsToConsole() {
    const pageData = getPageSettingsData();
    console.log('========================================');
    console.log('<<<<<<<  PAGE SETTINGS  >>>>>>>');
    if (!pageData) {
        console.error('<<<< Объект с настройками не получен');
        return;
    }
    console.log("template ", pageData.template),
        console.log("pageId ", pageData.pageId),
        console.log("pageTitle ", pageData.pageTitle),
        console.log("pageUrl ", pageData.pageUrl),
        console.log("collectionHandle ", pageData.collectionHandle),
        console.log("collectionTitle ", pageData.collectionTitle),
        console.log("collectionUrl ", pageData.collectionUrl),
        console.log("blocks ", pageData.blocks);
    console.log('----------------------------------------');
}
