export function showSelectedProductVariant() {
    const origin = `${window.location.protocol}//${window.location.host}`;
    console.log('<<<< ORIGIN >>>> ', origin);
    // Проверяем есть ли селект на странице выбора размеров - он в template = product
    // если элемента нет, выходим из функции
    const selectEl = document.getElementById('door-size-select');
    const parentSelectEl = selectEl?.parentElement;
    console.log('<<<<<<<<<<<< selectEl >>>>> ', selectEl);
    if (!selectEl)
        return;
    // Продолжаем скрипт, если селект есть и вешаем на него обработчик
    console.log('ПРОДОЛЖАЕМ СКРИПТ');
    function showSelectedVariantHandler(e) {
        console.log('СРАБОТАЛ ХЭНДЛЕР');
        console.log('EVENTTTTTTTTT', e);
        const options = selectEl?.querySelectorAll('option');
        const variants = [];
        console.log(options);
        options?.forEach((option) => {
            console.log('option.value >>>> ', option.value);
            console.log('option.textContent >>>> ', option.textContent);
            // const obj = {option.value: option.textContent};
            // variants.push({`${option.value}`: `${option.textContent}});
        });
        //@ts-ignore
        const result = Array.from(options).map(option => `${option.value}: ${option.textContent}`);
        console.log('RESULT >>>>>> ', result);
    }
    parentSelectEl?.addEventListener('click', (e) => {
        // e.stopPropagation();
        console.log('e.target - ', e.target);
        console.log('selectEl - ', selectEl);
        // console.log('e.target === selectEl - ', e.target === selectEl)
        // if (e.target === selectEl) {
        showSelectedVariantHandler(e);
        // }
    });
}
