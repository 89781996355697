// import { calcScrollbarWidth } from '../helpers/calcScrollbarWidth.js';
import { getProductByProductId, } from '../api/getProductByProductId.js';
import { showModalWidow, hideModalWidow, modalHandler, } from '../helpers/createModalWindow.js';
import { getClosestParentBySelector } from '../helpers/getClosestParentBySelector.js';
import { createModalWithVariants } from './createModalWithVariants.js';
import { postJSONData } from '../helpers/api/postJsonData.js';
import { changeCartCounter } from './changeCartCounter.js';
import { MODAL_DISPLAY_DELAY } from '../../main-new.js';
// import { handlerClickOnModal } from './handlerClickOnModal.js';
// console.log('0000000 - pageData', pageData.template);
export function getSelectedProductVariant() {
    // console.log('<<<<<<<<<< ДЕЛО БУДЕТ >>>>>>>>>>>>>');
    // const scrollbarWidth = calcScrollbarWidth();
    const origin = `${window.location.protocol}//${window.location.host}`;
    const formEls = document.querySelectorAll('form[data-product-id]');
    // console.log('<<<<<<< NEW formEls 12 13:45>>>>>>>', formEls);
    const addToBasketBtns = document.querySelectorAll('[data-add-to-cart]');
    // Блокировака кнопок 'В корзину'
    function disabledAddToBasketBtns() {
        addToBasketBtns.forEach((btn) => {
            btn.disabled = true;
            // console.log('BTN ЗАБЛОКИРОВАНА - > ', btn);
        });
    }
    // Разблокировка кнопок 'В корзину'
    function enabledAddToBasketBtns() {
        addToBasketBtns.forEach((btn) => {
            btn.disabled = false;
            // console.log('BTN РАЗБЛОКИРОВАНА - > ', btn);
        });
    }
    formEls.forEach((formEl) => {
        // Вешаем обработчик событий на каждую форму для блока кнопок
        formEl.addEventListener('click', async (e) => {
            // Клик по кнопке "Добавить в корзину" в карточке товара каталога
            e.preventDefault();
            console.log('<<<<<<< NEW CLICK >>>>>>>', e.currentTarget);
            // Клик осуществлен по кнопке 'В корзину' или её содержимому?
            const isClickOnAddToCartBtn = getClosestParentBySelector(e, '[data-add-to-cart]') !== null;
            // Если не целевой клик - выход из функции
            if (!isClickOnAddToCartBtn) {
                return;
            }
            const currentForm = e.currentTarget;
            const isOnlyVariant = currentForm.hasAttribute('data-only-variant');
            // Если у товара несколько вариантов, блокируем кнопки "В корзину", чтобы не было двойного клика или клика по кнопке другого товара
            if (!isOnlyVariant) {
                disabledAddToBasketBtns();
            }
            //@ts-ignore
            if (e.target.closest('[data-go-cart]')) {
                e.preventDefault();
                // enabledAddToBasketBtns();
                return;
            }
            const clickedFormEl = e.currentTarget; // Элемент формы
            const productId = clickedFormEl.getAttribute('data-product-id');
            if (!productId) {
                console.error(`Клик на кнопку "Добавить в корзину" в форме ${clickedFormEl}. Не найден id товара`);
                // enabledAddToBasketBtns();
                return;
            }
            const clickedAddToCardBtnEl = clickedFormEl.querySelector('[data-add-to-cart]'); // Элемент кнопка Добавить товар
            if (!clickedAddToCardBtnEl) {
                console.error(`Клик на кнопку "Добавить в корзину" в форме ${clickedFormEl}. Не найден элемент кнопки`);
                // enabledAddToBasketBtns();
                return;
            }
            // Формируем endpoint чтобы получить варианты товара
            const parameters = `front_api/products.json?ids=${productId}&with=variants`;
            const endpoint = `${origin}/${parameters}`;
            const product = await getProductByProductId(endpoint);
            if (!product) {
                // enabledAddToBasketBtns();
                return;
            }
            // console.log('<<<<< PRODUCT >>>>>', product);
            // console.log(product.length);
            // TODO нужно типизировать
            //@ts-ignore
            function setValuesAfterSelectingVariants(clickedFormEl, cart, variantId) {
                // console.log('ФУНКЦИЯ -  setValuesAfterSelectingVariants');
                // Меняем value на выбранное
                const variantInputEl = clickedFormEl.querySelector('input[name="variant_id"]');
                variantInputEl?.setAttribute('value', String(variantId));
                // Меняем счетчики и переключаем их стилизацию
                //@ts-ignore
                const counterNumber = cart.items.length
                    ? String(cart.items.length)
                    : '0';
                changeCartCounter(counterNumber, '[data-header-link-cart]', '[data-header-cart-count]');
                // Прячем кнопку "Добавить в корзину". Добавляем на форму класс.
                clickedFormEl.classList.add('catalog-addToBasket-form_added');
                // console.log('=========  clickedFormEl =========', clickedFormEl);
            }
            // Если вариант всего лишь один, нет смысла выводить модальное окно
            if (product[0].variants.length < 2) {
                // console.log('МАЛО ВАРИАНТОВ', product[0].variants.length);
                // console.log(product[0].id);
                // console.log(product[0].variants[0].id);
                const variantId = product[0].variants[0].id;
                const quantity = 1;
                const body = {
                    variant_id: variantId,
                    quantity,
                };
                const cart = await postJSONData('/cart_items.json', body, 'Отправка корзины');
                setValuesAfterSelectingVariants(clickedFormEl, cart, variantId);
                // enabledAddToBasketBtns();
                return;
            }
            const { dialogEl, addToCartMessageEl, dialogWrapperEl } = createModalWithVariants(product);
            showModalWidow(dialogEl);
            async function getSelectedVariantId(e) {
                // console.log('<<<<<<<<  ЧИТАЕМ  ЗДЕСЬ  >>>>>>>>');
                // console.log('=================================');
                // TODO посмотреть на место запуска этой функции
                enabledAddToBasketBtns();
                const desiredEl = getClosestParentBySelector(e, '[data-variant-id]');
                let variantId = null;
                if (desiredEl) {
                    variantId = desiredEl.getAttribute('data-variant-id');
                }
                if (!variantId) {
                    console.error(`Клик по кнопке с вариантом. Не найден variant-id ${variantId}`);
                    return;
                }
                // *********** Отправляем данные
                // получаем данные для body
                const quantityInputEl = clickedFormEl.querySelector('input[name="quantity"]');
                const quantity = quantityInputEl?.getAttribute('value');
                const body = {
                    variant_id: variantId,
                    quantity,
                };
                const cart = await postJSONData('/cart_items.json', body, 'Отправка корзины');
                if (!cart) {
                    return;
                }
                // Данные в корзину добавлены успешно
                // console.log('<<<<<<<< CART OБЪЕКТ >>>>>>>>', cart);
                // Показываем сообщение "Товар добавлен в корзину"
                addToCartMessageEl.classList.add('visual');
                // console.log('[[[[[[[[[[[[[[[ addToCartMessageEl  ]]]]]]]]]', addToCartMessageEl);
                // Прячем модальное окно
                dialogWrapperEl.classList.add('hidden');
                /*
                // Меняем value на выбранное
                const variantInputEl = clickedFormEl.querySelector('input[name="variant_id"]');
                variantInputEl?.setAttribute('value', variantId);
        
                // Меняем счетчики и переключаем их стилизацию
                //@ts-ignore
                const counterNumber = cart.items.length ? String(cart.items.length) : "0";
                changeCartCounter(counterNumber, '[data-header-link-cart]', '[data-header-cart-count]');
        
                // Прячем кнопку "Добавить в корзину". Добавляем на форму класс.
                clickedFormEl.classList.add('catalog-addToBasket-form_added');
                // console.log('=========  clickedFormEl =========', clickedFormEl);
        
                // console.log('=========  variantId =========', variantId);
        
                // console.log('=========  clickedFormEl =========', clickedFormEl);
        
                // console.log('=================================');
                */
                setValuesAfterSelectingVariants(clickedFormEl, cart, variantId);
                setTimeout(() => hideModalWidow(dialogEl), MODAL_DISPLAY_DELAY);
            }
            modalHandler(dialogEl, getSelectedVariantId);
        });
    });
}
