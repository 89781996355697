/**
 * Установка счётчика Яндекс Метрики
 *
 * @export
 * @param {string} targetElementsSelector
 * @param {string} yaMetrikaKey
 */
export function setYaMetrika(targetElementsSelector, yaMetrikaKey) {
    const targetElement = document.querySelector(targetElementsSelector);
    if (!targetElement) {
        console.error(`Элемент с селектором ${targetElementsSelector} не найден! Ключ ${yaMetrikaKey} не установлен!`);
        return;
    }
    targetElement.addEventListener('click', (e) => {
        e.preventDefault();
        try {
            const counterKey = Object.keys(window.yaCounter)[0];
            const yaCounterInstance = window.yaCounter[counterKey];
            if (yaCounterInstance && typeof yaCounterInstance.reachGoal === 'function') {
                yaCounterInstance.reachGoal(yaMetrikaKey);
            }
            else {
                console.error('Метод reachGoal не найден или не является функцией');
            }
        }
        catch {
        }
    });
}
