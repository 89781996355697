/**
 * Ищет ближайший родительский элемент, который соответсвует переданному в параметрах селектору
 *
 * @export
 * @param {Event} event
 * @param {string} selector
 * @param {string} [errorMessage=""]
 * @returns {(HTMLElement | null)}
 */
export function getClosestParentBySelector(event, selector, errorMessage = "") {
    // console.log('New событие - ', event);
    // console.log('New элемент - ', event.target);
    if (!event.target) {
        console.error(`Ошибка в событии - ${event.target}`);
        return null;
    }
    // Используем метод closest для поиска ближайшего родителя по селектору
    const targetEl = event.target.closest(selector);
    // if (!targetEl) {
    //     console.error(`Родительский элемент не найден. ${errorMessage}`);
    // }
    return targetEl;
}
