export async function postJSONData(endpoint, body, errorMessage = "") {
    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status} ${errorMessage}`);
        }
        const data = await response.json();
        return data;
    }
    catch (error) {
        console.error(`Error fetching data: ${error} ${errorMessage}`);
        throw error;
    }
}
